<template>
  <v-row>
    <!-- basic -->
    <v-form
      ref="form"
      lazy-validation
    >
      <v-col cols="12">
        <v-card>
          <v-card-title>Liquidación de Haberes del mes de {{ mesLiquidacion }} para <strong class="ml-2"> {{ employeeName }} </strong></v-card-title>
          <v-row>
            <v-col cols="6">
              <h2 style="color:#77b026 !important">
                Total a Liquidar $ {{ totalSalary }}
              </h2>
            </v-col>
            <v-col cols="6">
              <v-btn
                color="primary"
                :loading="isSaving"
                @click="recordSalary()"
              >
                Generar Liquidación
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="4"
            >
              <v-text-field
                v-model="totalBasico"
                :append-icon="icons.mdiAccountCard"
                :rules="[Validaciones.requerido]"
                label="Sueldo Fijo $"
                type="number"
                dense
                hide-details="true"
                outlined
              ></v-text-field>
            </v-col>
            <v-col
              cols="4"
            >
              <v-text-field
                v-model="premioClienteNuevo"
                :append-icon="icons.mdiWalletGiftcard"
                label="Premios Clientes Nuevos $"
                type="number"
                dense
                hide-details="true"
                outlined
              ></v-text-field>
            </v-col>
            <v-col
              cols="4"
            >
              <v-text-field
                v-model="premioCobranza"
                :append-icon="icons.mdiWalletGiftcard"
                label="Premios por Cobranzas $"
                type="number"
                dense
                hide-details="true"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="4"
            >
              <v-text-field
                v-model="comisiones"
                :append-icon="icons.mdiPercentOutline"
                label="Comisiones por Cobranza $"
                type="number"
                dense
                hide-details="true"
                outlined
              ></v-text-field>
            </v-col>
            <v-col
              cols="4"
            >
              <v-text-field
                v-model="otrosConceptos"
                :append-icon="icons.mdiDotsHorizontalCircleOutline"
                label="Otros Conceptos y/o Ajustes $"
                type="number"
                dense
                hide-details="true"
                outlined
              ></v-text-field>
            </v-col>
            <v-col
              cols="4"
            >
              <v-text-field
                v-model="tardanzas"
                :append-icon="icons.mdiClock"
                label="Tardanzas y/o inasistencias $"
                type="number"
                readonly
                dense
                hide-details="true"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="4"
            >
              <v-text-field
                v-model="totalLoan"
                :append-icon="icons.mdiHandCoin"
                label="Total Adelantos $"
                type="number"
                dense
                hide-details="true"
                readonly
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="8">
              <v-autocomplete
                v-model="selectLoan"
                :append-icon="icons.mdiSelectionEllipseArrowInside"
                :disabled="loadingLoan"
                :items="loanList"
                hide-details="true"
                dense
                label="Seleccionar Adelantos"
                :item-text="getItemText"
                item-value="id"
                :loading="loadingLoan"
                multiple
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index <2 ">
                    <span>{{ getItemText(item) }}</span>
                  </v-chip>
                  <span
                    v-if="index ===2 "
                    class="grey--text text-caption"
                  >

                    (más {{ selectLoan.length - 2 }})
                  </span>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-row>
        <v-col cols="6">
          <span>Fichada Turno Mañana</span>
          <v-data-table
            :headers="headers"
            :items="employeeDataMañana"
            item-key="id"
            sort-by="name"
            :header-props="headerProps"
            :footer-props="footerProps"
            :loading="loadingcheckout"
            loading-text="Cargando Fichada Turno Mañana..."
            no-data-text="No hay registros para mostrar."
            no-results-text="No se encontraron registros en la búsqueda"
          >
            <template v-slot:item.details="{ item }">
              <v-chip
                style="width:100%; text-align: center;"
                outlined
                small
                :color="getColor(item.details)"
                dark
              >
                {{ item.details }}
              </v-chip>
            </template>
          </v-data-table>
        </v-col>
        <v-col cols="6">
          <span>Fichada Turno Tarde</span>
          <v-data-table
            :headers="headers"
            :items="employeeDataTarde"
            item-key="id"
            sort-by="name"
            :header-props="headerProps"
            :footer-props="footerProps"
            :loading="loadingcheckout"
            loading-text="Cargando Fichada Turno Tarde..."
            no-data-text="No hay registros para mostrar."
            no-results-text="No se encontraron registros en la búsqueda"
          >
            <template v-slot:item.details="{ item }">
              <v-chip
                style="width:100%; text-align: center;"
                outlined
                small
                :color="getColor(item.details)"
                dark
              >
                {{ item.details }}
              </v-chip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-form>
  </v-row>
</template>

<script>
import axios from 'axios'
import {
  mdiCashMultiple, mdiPercentOutline, mdiAccountCard, mdiWalletGiftcard,
  mdiClock, mdiHandCoin, mdiDotsHorizontalCircleOutline, mdiSelectionEllipseArrowInside,
} from '@mdi/js'

export default {

  setup() {
    return {
      icons: {
        mdiCashMultiple,
        mdiPercentOutline,
        mdiAccountCard,
        mdiWalletGiftcard,
        mdiClock,
        mdiHandCoin,
        mdiDotsHorizontalCircleOutline,
        mdiSelectionEllipseArrowInside,

      },
      isSaving: false,
      totalSalary: 0,
      totalBasico: 0,
      premioClienteNuevo: 0,
      premioCobranza: 0,
      comisiones: 0,
      otrosConceptos: 0,
      tardanzas: 0,
      totalLoan: 0,
      employeeName: null,
      mesLiquidacion: '',
      loanList: [],
      selectLoan: 0,
      loadingcheckout: false,
      loadingLoan: false,
      salaryConcepts: [],
      Validaciones: {
        requerido: v => !!v || 'Campo Requerido',
      },
      headers: [
        { text: 'Fecha y Hora', value: 'date' },
        // eslint-disable-next-line no-useless-concat
        { text: 'Día', value: 'day' },

        // { text: 'Sexo', value: 'sex' },
        { text: 'Detalle', value: 'details' },
        { text: 'Monto', value: 'amount' },
      ],
      headerProps: {
        sortByText: 'Ordenar por',
      },
      footerProps: {
        itemsPerPageText: 'Registros por página',
        pageText: '{0}-{1} de {2}',
        itemsPerPageOptions: [5, 10, 20, 31],
      },
      employeeDataMañana: [],
      employeeDataTarde: [],
    }
  },
  watch: {
    selectLoan() {
      this.SumarAdelantos()
    },
    employeeName() {
      if (this.employeeName == null) {
        this.$router.push('salary')
      }
    },

    totalBasico() {
      this.calculateSalary()
    },
    premioClienteNuevo() {
      this.calculateSalary()
    },
    premioCobranza() {
      this.calculateSalary()
    },
    comisiones() {
      this.calculateSalary()
    },
    otrosConceptos() {
      this.calculateSalary()
    },
    tardanzas() {
      this.calculateSalary()
    },
    totalLoan() {
      this.calculateSalary()
    },

  },
  mounted() {
    this.employeeName = this.$route.params.name
    this.mesLiquidacion = this.moment((this.$route.params.date)).format('MMMM [de] YYYY')
    this.getLoanList()
    this.getListCheckInOutSalary()
  },
  methods: {
    getColor(details) {
      if (details === 'No Asistió') return 'error'
      if (details === 'Muy Tarde') return 'error'
      if (details === 'Tarde') return 'second'
      if (details === 'No Laborable') return 'info'
      if (details === 'En Horario') return 'success'
      if (details === 'Licencia por Enfermedad') return 'disease'
      if (details === 'Franco') return 'license'
      if (details === 'Vacaciones') return 'vacation'
      if (details === 'Licencia Especial') return 'others'

      return 'secondary'
    },
    async createSalaryArray() {
      this.salaryConcepts = []
      await this.salaryConcepts.push(
        {
          description: 'Sueldo Básico',
          amount: parseFloat(this.totalBasico).toFixed(2),
        },
        {
          description: 'Premios por Cobranza',
          amount: parseFloat(this.premioCobranza).toFixed(2),
        },
        {
          description: 'Premios Nuevos Clientes',
          amount: parseFloat(this.premioClienteNuevo).toFixed(2),
        },
        {
          description: 'Comisiones',
          amount: parseFloat(this.comisiones).toFixed(2),
        },
        {
          description: 'Tardanzas y/o Inasistencias',
          amount: parseFloat(this.tardanzas).toFixed(2) * -1,
        },
        {
          description: 'Otros Conceptos y/o Ajustes',
          amount: parseFloat(this.otrosConceptos).toFixed(2),
        },
        {
          description: 'Adelantos',
          amount: parseFloat(this.totalLoan).toFixed(2) * -1,
        },
      )
    },
    sumarTardanzas() {
      let sumatotalmañana = 0
      let sumatotalTarde = 0
      this.employeeDataMañana.forEach(element => {
        sumatotalmañana += parseFloat(element.amount)
      })
      this.employeeDataTarde.forEach(element => {
        sumatotalTarde += parseFloat(element.amount)
      })
      this.tardanzas = (parseFloat(sumatotalmañana) + parseFloat(sumatotalTarde)).toFixed(2)
    },
    SumarAdelantos() {
      let sumatotal = 0
      this.totalLoan = 0
      this.loanList.forEach(element => {
        if (this.selectLoan.includes(element.id)) {
          sumatotal += parseFloat(element.amount)
          this.totalLoan = sumatotal.toFixed(2)
        }
      })
    },
    calculateSalary() {
    //  if (tirm(this.totalSalary) == '') this.totalSalary = 0
      let CalculoTotal = 0
      if (this.totalBasico === '') this.totalBasico = 0
      if (this.premioClienteNuevo === '') this.premioClienteNuevo = 0
      if (this.premioCobranza === '') this.premioCobranza = 0
      if (this.comisiones === '') this.comisiones = 0
      if (this.otrosConceptos === '') this.otrosConceptos = 0
      if (this.tardanzas === '') this.tardanzas = 0
      if (this.totalLoan === '') this.totalLoan = 0
      CalculoTotal = (parseFloat(this.totalBasico) + parseFloat(this.premioClienteNuevo) + parseFloat(this.premioCobranza)
       + parseFloat(this.comisiones) + parseFloat(this.otrosConceptos)) - (parseFloat(this.tardanzas) + parseFloat(this.totalLoan))
      this.totalSalary = CalculoTotal.toFixed(2)
    },
    recordSalary() {
      this.calculateSalary()
      this.createSalaryArray()
      this.CreateSalary()
    },
    getItemText(item) {
      return `${item.loanDate} - Monto: $ ${item.amount}`
    },
    async getLoanList() {
      this.loadingLoan = true
      this.url = `${this.$store.getters.urlBase}/api/Salary/LoanList`
      const options = {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
        },
        params: { idPerson: this.$route.params.id },
        url: this.url,
      }
      const respuesta = await axios(this.url, options)
      if (respuesta.status === 200) {
      // console.log(respuesta.data);
        this.loanList = respuesta.data
        this.loadingLoan = false

      // this.getListarDiasMantenimiento();
      } else {
      // this.colorSnack = "red";
      // this.mensajeSnack = respuesta.data;
      // this.Showsnackbar = true;
        this.loadingLoan = false
      }
    },
    async CreateSalary() {
      if (this.$refs.form.validate()) {
        this.isSaving = true
        if (this.selectLoan === 0) {
          this.selectLoan = []
        }
        this.url = `${this.$store.getters.urlBase}/api/Salary/CreateSalary`
        const params = {
          personId: this.$route.params.id,
          personIdLogged: parseInt(this.$store.getters.usuario.user_personid),
          salaryConcepts: this.salaryConcepts,
          loanList: this.selectLoan,
          settlementMonth: this.$route.params.date,
        }
        const config = {
          headers: {
            Authorization: `Bearer ${this.$store.getters.usuario.token.token}`,
            'content-type': 'application/json',
          },
        }
        await axios
          .post(this.url, params, config)
          .then(
            // eslint-disable-next-line no-return-assign
            response => {
              this.respuesta = response
              this.isSaving = false
              if (this.respuesta.status === 200) {
                this.$store.dispatch('showSnack', {
                  text: 'Creacion exitosa',
                  color: 'success',
                  timeout: 2500,
                })
                this.$router.push({ name: 'reportsalary', params: { salaryId: this.respuesta.data.salaryID } })
              }
            },
          )
          .catch(e => {
            if (e.response) {
              if (e.response.data) {
                this.mensajeSnack = this.respuesta.data
              } else {
                this.mensajeSnack = this.respuesta.data
              }
            } else {
              this.mensajeSnack = 'Error de conexion'
            }

            this.isSaving = false
          })
      }
    },
    async getListCheckInOutSalary() {
      this.loadingcheckout = true
      this.url = `${this.$store.getters.urlBase}/api/Salary/CheckInOutForSalary`
      const options = {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
        },
        params: { personId: this.$route.params.id, date: this.$route.params.date },
        url: this.url,
      }
      const respuesta = await axios(this.url, options)
      if (respuesta.status === 200) {
      // console.log(respuesta.data);
        this.employeeDataMañana = respuesta.data.morningCheckin
        this.employeeDataTarde = respuesta.data.eveningCheckin
        this.loadingcheckout = false
        this.sumarTardanzas()

      // this.getListarDiasMantenimiento();
      } else {
      // this.colorSnack = "red";
      // this.mensajeSnack = respuesta.data;
      // this.Showsnackbar = true;
        this.loadingcheckout = false
      }
    },
  },
}
</script>
